/* Custom layout */
.pluto-color-picker .react-colorful {
  width: auto;
  min-width: 248px;
}

.pluto-color-picker .react-colorful__saturation {
  @apply mb-4;
  border-radius: 5px;
  border-bottom: none;
}

.pluto-color-picker .react-colorful__hue {
  //order: -1;
  @apply mb-0;

}

.pluto-color-picker .react-colorful__hue,
.pluto-color-picker .react-colorful__alpha {
  height: 14px;
  border-radius: 5px;
}

.pluto-color-picker .react-colorful__hue-pointer,
.pluto-color-picker .react-colorful__alpha-pointer,
.pluto-color-picker .react-colorful__saturation-pointer {
  width: 12px;
  height: 12px;
}
