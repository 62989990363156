@mixin field-label {
    @apply inline-block w-full font-semibold tracking-tight text-default dark:text-white;
    &:not(.no-margin) {
        @apply mb-1;
    }

    &.flex {
        display: flex;
    }
}

.field-label {
    @include field-label;
}

.form-field {
    @apply block;

    &:not(.no-margin) {
        @apply mb-8;
    }

    .field-input,
    textarea,
    input[type='text'],
    input[type='number'],
    input[type='password'],
    input[type='email'] {
        @apply relative
    block
    w-full
    appearance-none
    rounded-md
    border
    border-gray-300
    px-3
    py-2 text-base
    text-gray-900 placeholder-gray-400
    hover:border-gray-400 focus:z-10
    focus:outline-none focus:ring-primary
    dark:border-white/70
    dark:bg-transparent
    dark:text-white/90 dark:placeholder-white/60
    dark:hover:border-white/90
    dark:focus:ring-secondary;
    }

    .field-input.placeholder {
        @apply text-gray-500 placeholder-gray-500 dark:text-white/60 dark:placeholder-white/60;
    }

    .help-text {
        @apply text-gray-500;
    }

    &.disabled {
        .field-input,
        input {
            @apply text-gray-400 dark:text-gray-200;
        }
    }

    &.has-error {
        .field-input,
        input {
            @apply border-2 border-error;
        }

        input[type='checkbox'] {
            @apply border-2 border-error;
        }

        .field-icon {
            @apply text-error-light;
        }
    }
}

input[type='checkbox'] {
    &.error {
        @apply border-2 border-error;
    }
}

#hubspot-form-wrapper {
    .form-columns-1,
    .form-columns-2 {
        margin-top: 10px;
    }

    label {
        // same code as .field-label
        @include field-label;
    }

    .input {
        .field-input,
        textarea,
        input[type='text'],
        input[type='number'],
        input[type='password'],
        input[type='email'],
        select {
            @apply relative
      block
      w-full
      appearance-none
      rounded-md
      border
      border-gray-300
      px-3
      py-2 text-base
      text-gray-900 placeholder-gray-500
      hover:border-gray-400 focus:z-10
      focus:outline-none focus:ring-primary
      dark:border-white/70
      dark:bg-transparent
      dark:text-white/90 dark:placeholder-white/60
      dark:hover:border-white/90
      dark:focus:ring-secondary;
        }
    }
    input[type='submit'] {
        background-color: #3343ab;
        color: #fff;
        border-radius: 16px;
        padding: 10px 22px;
        margin-top: 18px;
    }
    .hs-error-msg {
        @apply text-error-light;
    }
}
