@tailwind base;
@tailwind components;
@tailwind utilities;
@import 'forms';
@import 'nprogress';

@font-face {
    font-family: 'DejaVu Sans';
    src: url('/fonts/DejaVuSans.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'DejaVu Sans';
    src: url('/fonts/DejaVuSans-Bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Computer Modern';
    src: url('/fonts/cmu.sans-serif-medium.ttf') format('truetype');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
}
  
@font-face {
    font-family: 'Computer Modern';
    src: url('/fonts/cmu.sans-serif-bold.ttf') format('truetype');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}
/* open-sans-300 - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: url('/fonts/open-sans-v40-latin-300.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-300italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 300;
    src: url('/fonts/open-sans-v40-latin-300italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-regular - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/open-sans-v40-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/open-sans-v40-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-500 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/open-sans-v40-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-500italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 500;
    src: url('/fonts/open-sans-v40-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-600 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/open-sans-v40-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-600italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: url('/fonts/open-sans-v40-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-700 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/open-sans-v40-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-700italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/open-sans-v40-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-800 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/open-sans-v40-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* open-sans-800italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 800;
    src: url('/fonts/open-sans-v40-latin-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }

  /* eb-garamond-regular - latin */
@font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/eb-garamond-v30-latin-regular.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* eb-garamond-italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/eb-garamond-v30-latin-italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* eb-garamond-500 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 500;
    src: url('/fonts/eb-garamond-v30-latin-500.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* eb-garamond-500italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 500;
    src: url('/fonts/eb-garamond-v30-latin-500italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* eb-garamond-600 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 600;
    src: url('/fonts/eb-garamond-v30-latin-600.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* eb-garamond-600italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 600;
    src: url('/fonts/eb-garamond-v30-latin-600italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* eb-garamond-700 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/eb-garamond-v30-latin-700.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* eb-garamond-700italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/eb-garamond-v30-latin-700italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* eb-garamond-800 - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: normal;
    font-weight: 800;
    src: url('/fonts/eb-garamond-v30-latin-800.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }
  /* eb-garamond-800italic - latin */
  @font-face {
    font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
    font-family: 'EB Garamond';
    font-style: italic;
    font-weight: 800;
    src: url('/fonts/eb-garamond-v30-latin-800italic.woff2') format('woff2'); /* Chrome 36+, Opera 23+, Firefox 39+, Safari 12+, iOS 10+ */
  }

html {
    font-size: 16px;
    scroll-behavior: smooth;
    @apply h-full;
}

body {
    @apply h-full text-sm;
    overscroll-behavior: none;
}

a,
span.link {
    color: #3343ab;
    font-weight: 600;
    text-decoration: none;
    transition: color ease 0.3s;
    cursor: pointer;
}

a:hover,
span.link:hover {
    color: #616ecf;
}

details summary {
    cursor: pointer;
    padding: 0 0 10px 0;
}

@layer utilities {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }
    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }
}

@layer base {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        @apply text-dark;
    }
}

@layer components {
    table.rounded-corners {
        border-spacing: 0;

        thead tr:first-child th:first-child {
            @apply rounded-tl-xl;
        }

        /* the last 'th' within the first 'tr' of the 'thead': */
        thead tr:first-child th:last-child {
            @apply rounded-tr-xl;
        }

        /* the first 'td' within the last 'tr' of the 'tbody': */
        tbody tr:last-child td:first-child {
            @apply rounded-bl-xl;
        }

        /* the last 'td' within the last 'tr' of the 'tbody': */
        tbody tr:last-child td:last-child {
            @apply rounded-br-xl;
        }
    }

    table.rounded-corners-md {
        border-spacing: 0;

        thead tr:first-child th:first-child {
            @apply rounded-tl-md;
        }

        /* the last 'th' within the first 'tr' of the 'thead': */
        thead tr:first-child th:last-child {
            @apply rounded-tr-md;
        }

        /* the first 'td' within the last 'tr' of the 'tbody': */
        tbody tr:last-child td:first-child {
            @apply rounded-bl-md;
        }

        /* the last 'td' within the last 'tr' of the 'tbody': */
        tbody tr:last-child td:last-child {
            @apply rounded-br-md;
        }
    }

    .fill-white {
        fill: white;
    }

    .fill-none {
        fill: none;
    }

    .chart-tooltip {
        background: white;
        @apply rounded-lg border border-indigo-300 px-4 py-2 text-base font-semibold shadow-xl;
    }

    .data-point {
        @apply stroke-1;
    }

    .hide-scrollbar {
        /* Hide scrollbar for Chrome, Safari and Opera */
        &::-webkit-scrollbar {
            display: none;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
    }

    .backface-visible {
        backface-visibility: visible;
    }

    .backface-invisible {
        backface-visibility: hidden;
    }

    .translate-y-0-3d {
        transform: translate3d(0, 0, 0);
    }

    .translate-y-full-3d {
        transform: translate3d(0, 100%, 0);
    }

    .-translate-y-full-3d {
        transform: translate3d(0, -100%, 0);
    }

    .prose :where(details):not(:where([class~='not-prose'] *)) {
        @apply mb-4 px-5;

        summary {
            @apply -mx-5 pb-0;
            > * {
                @apply pl-1.5;
            }
        }
    }

    .prose {
        .contains-task-list {
            .task-list-item {
                input[type='checkbox'] {
                    margin: 0;
                }
            }
        }
    }
}

.header-grid {
    -ms-overflow-style: none; /* IE 10+ */
    scrollbar-width: none; /* Firefox */
}

.header-grid::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera */
}

// Import component specific files
@import 'dashboard';
@import 'igv';
@import 'code';
@import 'react-colorful';
@import 'mui';
@import 'react-carousel';

/* Blast hubspot chat widget off screen on mobile */
@media screen and (max-width: 768px) {
    #hubspot-messages-iframe-container {
        margin-bottom: -1000px;
    }
}

/* react-transition-group exit/enter animation */
.item-exit-active {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 500ms ease-out;
}
.item-enter {
    opacity: 0;
    transform: translateY(2rem);
    transition: all 500ms ease-out;
}
.item-enter-active {
    opacity: 1;
    transform: translateY(0);
    transition: all 500ms ease-out;
}
.fade-exit-active {
    opacity: 0;
    transition: all 300ms ease-out;
}
.fade-enter {
    opacity: 0;
    transition: all 300ms ease-out;
}
.fade-enter-active {
    opacity: 1;
    transition: all 300ms ease-out;
}
.fade-enter-done {
    opacity: 1;
    transition: all 300ms ease-out;
}

.prose.text-sm h1 {
    font-size: 1rem;
}

.prose.text-sm h2 {
    font-size: 0.9rem;
}

.prose.text-sm h3 {
    font-size: 0.8;
}

// Canvas
$icon-size: 40px; // Size of the icons
$canvas-border-radius: 5px; // Border radius of the icons
$icon-background: #ccc; // Background color of the icons
$icon-border: 1px solid #ccc; // Border of the icons
$distance-from-center: 80px; // Distance from the center
$base-angle: -110deg; // Starting angle for the first icon
$spacing-angle: 50deg; // Angle between each icon

@keyframes rotateIcons {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg); // Complete a full rotation
    }
}

.iconsContainer {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: -1;

    .iconsInner {
        z-index: -1;
        transform: rotate(90deg);
        transition: all 0.4s ease-in-out; // Smooth transition for the transform
        transform-origin: center center; // Ensure rotation happens around the center
        opacity: 0;

        &.rotateIn {
            transform: rotate(0deg); // Rotates outwards when selected
            opacity: 100;
        }
        &.rotateOut {
            transform: rotate(90deg); // Rotates back inwards when not selected
            opacity: 0;
            pointer-events: none;
        }

        .icon {
            position: absolute;
            z-index: -1;
            padding: 4px;
            border-radius: $canvas-border-radius;
            border: $icon-border;
            background: #fff;
            text-align: center;
            cursor: pointer;

            svg {
                width: $icon-size;
                height: $icon-size;
            }

            &:nth-child(1) {
                $angle: $base-angle;
                transform: translate(-50%, -50%) rotate($angle) translate($distance-from-center) rotate(-$angle);
            }
            &:nth-child(2) {
                $angle: $base-angle + $spacing-angle;
                transform: translate(-50%, -50%) rotate($angle) translate($distance-from-center) rotate(-$angle);
            }
            &:nth-child(3) {
                $angle: $base-angle + 2 * $spacing-angle;
                transform: translate(-50%, -50%) rotate($angle) translate($distance-from-center) rotate(-$angle);
            }
            &:nth-child(4) {
                $angle: $base-angle + 3 * $spacing-angle;
                transform: translate(-50%, -50%) rotate($angle) translate($distance-from-center) rotate(-$angle);
            }
        }
    }
}

// Home UI
.homeUI {
    background-color: #06141b;
    width: 100%;
    height: 100vh;
    background: url('https://pluto.bio/backgrounds/hero-background1.png');
    background-size: cover;
    background-repeat: no-repeat;

    img.loading {
        margin: 0 auto;
        padding-top: 22vh;
    }
}

// ReactFlow
.react-flow__node {
    .react-flow__handle {
        width: 12px !important;
        height: 12px !important;
        background: #ad05b1;
    }
    .react-flow__resize-control.handle {
        width: 12px !important;
        height: 12px !important;
    }
    .react-flow__handle.connectionindicator {
        width: 12px !important;
        height: 12px !important;
        background: #ad05b1;
    }
    .react-flow__handle-left {
        left: -7px !important;
    }
    .react-flow__handle-right {
        right: -7px !important;
    }
    .react-flow__handle-top {
        top: -7px !important;
    }
    .react-flow__handle-bottom {
        bottom: -7px !important;
    }
}

// Font Styles
.pluto-gradient {
    background-image: linear-gradient(90deg, #4b1590, #ad05b1);
}

.pluto-gradient2 {
    background-image: linear-gradient(90deg, #ad05b1, #4b1590);
}

.pluto-gradient3 {
    background-image: linear-gradient(90deg, #96b9e3, #e0ecfb);
}

@mixin button-size($padding-y, $padding-x, $font-size) {
    padding: $padding-y $padding-x;
    font-size: $font-size;
}

.hyphenate {
    display: inline-block;
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
    -webkit-hyphens: auto;
    -moz-hyphens: auto;
    hyphens: auto;
}

// Fonts
$font-family: 'Open Sans', sans-serif;

$small-font-size: 0.8rem;
$medium-font-size: 0.9rem;
$large-font-size: 1.2rem;

$primary-color: #4b1590;
$secondary-color: #ad05b1;
$light-blue: #426287;
$background-purple: #ede8ff;

$button-border-radius: 20px;

.custom-buttons {
    .button {
        @include button-size(11px, 22px, 1rem);
        border-radius: $button-border-radius;
        background-color: $secondary-color;
        color: #fff;
        font-weight: 600;
        cursor: pointer;
        transition: background-color 0.5s ease-in-out;
        display: inline-block;
        border: none;

        &:hover {
            background-color: lighten($secondary-color, 10%);
        }

        &-small {
            @include button-size(6px, 14px, $small-font-size);
        }

        &-medium {
            @include button-size(9px, 20px, $medium-font-size);
        }

        &-large {
            @include button-size(8px, 16px, $large-font-size);
        }
    }
    .button-gradient {
        background: linear-gradient(45deg, $secondary-color, $primary-color);
    }
    .button-purple {
        background: $primary-color;

        &:hover {
            background: darken($primary-color, 10%);
        }
    }

    .button-blue {
        background: $light-blue;

        &:hover {
            background: darken($light-blue, 10%);
        }
    }

    .button-shadow {
        box-shadow: 0 10px 15px -3px rgba(16, 24, 40, 0.3);
    }
}

[type='text']:focus {
    --tw-ring-color: #fff;
}

// shake animation
@keyframes shake {
    0%,
    100% {
        transform: rotate(0deg);
    }
    10%,
    30%,
    50%,
    70%,
    90% {
        transform: rotate(-10deg);
    }
    20%,
    40%,
    60%,
    80% {
        transform: rotate(10deg);
    }
}

.animate-shake {
    animation: shake 1s ease-in-out; /* Increased duration for more shakes */
}

@keyframes gradientRotate {
    0% { background-position: 0% 0%; }    /* Top-left */
    25% { background-position: 80% 0%; }  /* Top-right */
    50% { background-position: 80% 80%; } /* Bottom-right */
    75% { background-position: 0% 80%; }  /* Bottom-left */
    100% { background-position: 0% 0%; }   /* Back to Top-left */
}

.animate-gradientRotate {
    background-size: 300% 300%;
    animation: gradientRotate 5s infinite linear;
}

@keyframes gradientBounce {
    0% { background-position: 50% 0%; }
    50% { background-position: 50% 100%; }
    100% { background-position: 50% 0%; }
}

.animate-gradientBounce {
    background-size: 300% 300%;
    animation: gradientBounce 3s infinite linear;
}