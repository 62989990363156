.pluto-igv {

  &:not(.igv-publication-mode) {
    .igv-container {
      @apply border border-indigo-100 p-0 pt-0 rounded-lg pb-4 h-full w-full;
    }
  }

  .igv-track-drag-column, .igv-gear-menu-column {
    display: none;
  }

  .igv-container {
    @apply p-0 pt-0 rounded-lg pb-4 flex-1 w-full h-full;
  }

  .igv-navbar {
    @apply bg-indigo-100 py-2 px-2 flex-col md:flex-row h-auto items-start md:items-center border-none border-0 mt-0 rounded-b-none rounded-t-lg relative -top-[1px];

    .igv-logo {
      @apply text-dark hidden;
    }

    .igv-navbar-left-container {
      @apply md:flex-row h-auto md:items-center items-start;

      .igv-navbar-genomic-location .igv-locus-size-group .igv-search-container {
        input.igv-search-input {
          @apply py-0.5 h-auto border-gray-500;
        }
      }

    }

    .igv-navbar-right-container {
      @apply items-end self-end;
      .igv-zoom-widget, .igv-zoom-widget-900 {
        @apply text-primary accent-primary;
      }
    }


    .igv-chromosome-select-widget-container {
      select {
        padding: 1px 0 1px 4px;
        border-radius: 3px;
        background-position: right 1px center;
      }

    }

    .igv-search-icon-container {
      @apply text-primary
    }
  }


  .igv-navbar-button {
    @apply text-dark font-sans bg-gray-100 border-gray-400 px-2 py-1;

    &:hover {
      @apply bg-primary/20;
    }

    &.igv-navbar-button-clicked {
      @apply bg-primary text-white border-primary
    }
  }


  .igv-gear-menu-column {
    & > div > div {
      @apply text-secondary;
    }
  }

  .igv-track-drag-column {

    .igv-track-drag-handle {
      @apply bg-secondary/50;

      &.igv-track-drag-handle-hover {
        @apply bg-secondary;
      }
    }
  }

  .igv-viewport {
    @apply h-full w-full;
    .igv-loading-spinner-container {
      & > div {
        @apply border-4 border-primary border-t-white
      }
    }

    .igv-track-label {
      @apply text-dark rounded;
    }
  }

  .igv-column-container {
    @apply w-full;
  }
}


