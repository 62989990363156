.updatedProjects {
    .scrollContainer {
        $repeat: 3;
        flex-grow: 1;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat($repeat, 280px);
        grid-template-rows: 1fr;
        scroll-snap-type: x proximity;

        //@apply pr-6;

        @screen sm {
            grid-gap: 1.5rem;
        }

        @screen xl {
            @apply grid-cols-3;
            grid-template-rows: unset;
        }
    }

    .item {
        scroll-snap-align: center;
        flex-shrink: 0;

        &:last-child {
            padding-right: 2rem;
            margin-right: -2rem;
        }
    }
}

.recentExperiments {
    .scrollContainer {
        $repeat: 4;
        flex-grow: 1;
        display: grid;
        grid-gap: 1rem;
        grid-template-columns: repeat($repeat, 220px);
        grid-template-rows: 1fr;
        scroll-snap-type: x proximity;

        @screen xl {
            @apply grid-cols-4;
            grid-template-rows: unset;
        }
    }

    .item {
        scroll-snap-align: center;
        flex-shrink: 0;

        &:last-child {
            padding-right: 2rem;
            margin-right: -2rem;
        }
    }
}
